<template>
    <div class="service-page" :style="{background:background}">
        <div class="services-scroll-areas" :style="{height:scrollHeight+'px'}">
            <slot></slot>
        </div>
        <div class="services-page-bottoms" :style="{height:realBottomHeight+'px'}">
            <slot name="pageBottomButtons"></slot>
            <div class="iosButtonLine" v-if="showIosBottomButtons"></div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'rsck-page',
    props: {
      showPageBottomButton: {
        type: Boolean,
        default: false
      },
      showIosBottomButtons: {
        type: Boolean,
        default: false
      },
      bottomHeight: {
        type: [String, Number],
        default: 40
      },
      background: {
        type: String,
        default: ''
      }
    },
    computed: {
      realBottomHeight () {
        // console.log('this.$props.bottomHeight',this.$props.bottomHeight)
        let height = 0
        if (this.$props.showPageBottomButton) {
          height += parseInt(this.$props.bottomHeight)
        }
        if (this.$props.showIosBottomButtons) {
          height += 32
        }
        return height
      },
      scrollHeight () {
        let height = window.innerHeight
        if (this.$props.showPageBottomButton) {
          height -= parseInt(this.$props.bottomHeight)
        }
        if (this.$props.showIosBottomButtons) {
          height -= 32
        }
        return height
      }
    },
    methods: {}
  }
</script>

<style scoped lang="scss">
    .service-page {
        background: #f6f6f6;

        .services-scroll-areas {
            width: 100%;
        }

        .services-page-bottoms {
            background: #fff;
            z-index: 999;
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            border-top: solid 1px #f6f6f6;
            display: flex;
            align-items: center;

            .iosButtonLine {
                height: 32px;
                width: 100%;
                background: #fff;
            }
        }
    }
</style>